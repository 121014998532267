import React from 'react'
import { navigate, graphql } from 'gatsby'

import { isLoggedIn, LoginPath } from '../services/auth'

export default function Template({
  data // this prop will be injected by the GraphQL query below
}) {
  if (typeof window === 'undefined') return null
  if (!isLoggedIn(window)) {
    navigate(LoginPath)
    return null
  }
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <div style={{ background: '#1e2247', color: '#85E582' }}>
      <div
        className='lucky-font'
        style={{
          maxWidth: 1200,
          paddingTop: 48,
          margin: 'auto'
        }}
      >
        <div className='blog-post'>
          <h1 className='lucky-font-italic' style={{ color: '#D48CF8' }}>
            {frontmatter.title}
          </h1>
          <h2 className='lucky-font-italic' style={{ color: '#D48CF8' }}>
            {frontmatter.date}
          </h2>
          <div
            style={{ fontSize: 20 }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
